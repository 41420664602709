import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Description.module.scss';

export enum DescriptionSizes {
  medium = 'medium',
  large = 'large',
}

export interface DescriptionProps extends HTMLAttributes<HTMLHeadingElement> {
  size?: keyof typeof DescriptionSizes;
}

export default function Description(
  props: PropsWithChildren<DescriptionProps>,
) {
  const {
    size = DescriptionSizes.medium,
    children,
    className,
    ...restProps
  } = props;

  return (
    <div {...restProps} className={cn(styles.wrapper, styles[size], className)}>
      {children}
    </div>
  );
}
