import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Title.module.scss';

export enum TitleSizes {
  default = 'default',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
  size?: keyof typeof TitleSizes;
}

export default function Title(props: PropsWithChildren<TitleProps>) {
  const { size = TitleSizes.medium, children, className, ...restProps } = props;

  return (
    <h3 {...restProps} className={cn(styles.heading, styles[size], className)}>
      {children}
    </h3>
  );
}
