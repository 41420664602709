import styles from './Topics.module.scss';

export interface TopicsProps {
  list: string[];
}

export default function Topics(props: TopicsProps) {
  const { list } = props;

  return (
    <div className={styles.wrapper}>
      {list.map((item) => (
        <div key={item} className={styles.topic}>
          {item}
        </div>
      ))}
    </div>
  );
}
