import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  ProductCardSizes,
  ProductsBlogGridItemType,
} from '../../products/ProductsBlogGrid';
import { CardAnchor } from '../components';
import ProductBlogCard, {
  ProductBlogCardVariants,
} from '../../products/cardBuilders/ProductBlogCard';
import Topics from './components/Topics';
import Description from './components/Description';
import Title from './components/Title';
import Image from './components/Image';

export interface ArticleBlogCardProps {
  data: ProductsBlogGridItemType;
  variant?: keyof typeof ProductBlogCardVariants;
  displayImageMobileOnly?: boolean;
  fullWidth?: boolean;
  pathPrefix?: string;
  productSize?: ProductCardSizes;
}

export default function ArticleBlogCard(props: ArticleBlogCardProps) {
  const {
    data: {
      path,
      title,
      imageUrl,
      tags = [],
      summary,
      externalLinkEnabled,
      externalLink,
    },
    variant = ProductBlogCardVariants.default,
    productSize = ProductCardSizes.small,
    pathPrefix,
    displayImageMobileOnly,
    fullWidth,
  } = props;
  const articlePath = externalLinkEnabled ? externalLink : path;

  const getArticleTags = (topics: string[]) => {
    const articleTags = topics.slice(0, 2);

    if (articleTags.length === 0) {
      return null;
    }

    return <Topics list={articleTags} />;
  };

  return (
    <CardAnchor
      to={articlePath}
      pathPrefix={pathPrefix}
      data-testid="article-blog-card"
      fullWidth={fullWidth}
    >
      <ProductBlogCard
        variant={variant}
        image={
          <Image path={imageUrl} displayMobileOnly={displayImageMobileOnly} />
        }
        topics={getArticleTags(tags)}
        heading={
          <Title size={productSize}>
            {title}{' '}
            {externalLinkEnabled && (
              <OpenInNewIcon
                fontSize="inherit"
                sx={{ verticalAlign: 'middle' }}
              />
            )}
          </Title>
        }
        description={summary && <Description>{summary}</Description>}
      />
    </CardAnchor>
  );
}
