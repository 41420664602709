import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../../../types';
import { useGetImgixImageUrl } from '../../../../../../../../hooks';

import styles from './Image.module.scss';

export enum ImageStyles {
  featured = 'featured',
}

export interface ImageProps {
  path: string;
  imageStyle?: keyof typeof ImageStyles;
  displayMobileOnly?: boolean;
}

export default function Image(props: PropsWithTestId<ImageProps>) {
  const {
    path,
    imageStyle,
    displayMobileOnly,
    testId = 'site-article-card-image',
  } = props;
  const image = useGetImgixImageUrl(path);

  const wrapperClassNames = cn(styles.wrapper, {
    [styles.showOnMobileOnly]: displayMobileOnly,
  });
  const imageClassNames = cn(styles.image, {
    [styles.featured]: imageStyle === ImageStyles.featured,
  });

  return (
    <div className={wrapperClassNames}>
      <div
        className={imageClassNames}
        style={{ backgroundImage: image ? `url(${image})` : 'none' }}
        data-testid={testId}
      />
    </div>
  );
}
