import { ReactNode } from 'react';
import cn from 'classnames';
import { SiteArticle } from '../../../../../../store/features/siteArticles';
import { UniqueIdentifier } from '../../../../../../types';

import styles from './ProductsBlogGrid.module.scss';

export type ProductsBlogGridItemType = Omit<
  SiteArticle,
  'datePublished' | 'authorName'
>;

export enum ProductCardSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export interface RenderProductData<T> {
  product: T;
  productIndex?: number;
  productSize?: ProductCardSizes;
}

export interface ProductsBlogGridProps<T> {
  largeProductItems: T[];
  mediumProductItems: T[];
  smallProductItems: T[];
  renderProduct: (renderProps: RenderProductData<T>) => ReactNode;
}

export default function ProductsBlogGrid<T extends { id: UniqueIdentifier }>(
  props: ProductsBlogGridProps<T>,
) {
  const {
    largeProductItems,
    mediumProductItems,
    smallProductItems,
    renderProduct,
  } = props;
  const isOneFeaturedProductItem =
    largeProductItems.length === 1 && mediumProductItems.length === 0;

  return (
    <div data-testid="products-blog-grid">
      <div className="row">
        {largeProductItems.length > 0 && (
          <div
            className={cn('col-xs-12', {
              'col-sm-8': !isOneFeaturedProductItem,
            })}
          >
            {largeProductItems.map((product, index) => (
              <div className={styles.largeCard} key={product.id}>
                {renderProduct({
                  product,
                  productIndex: index,
                  productSize: ProductCardSizes.large,
                })}
              </div>
            ))}
          </div>
        )}

        {mediumProductItems.length > 0 && (
          <div className="col-xs-12 col-sm-4">
            <div className={cn('row', styles.mediumCardsWrapper)}>
              {mediumProductItems.map((product, index) => (
                <div className="col-xs-12" key={product.id}>
                  <div className={styles.mediumCard}>
                    {renderProduct({
                      product,
                      productIndex: index,
                      productSize: ProductCardSizes.medium,
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {smallProductItems.length > 0 && (
        <div className={styles.smallCardsWrapper}>
          <div className="row">
            {smallProductItems.map((product, index) => (
              <div className="col-xs-12 col-sm-6 col-md-3" key={product.id}>
                <div className={styles.smallCard}>
                  {renderProduct({
                    product,
                    productIndex: index,
                    productSize: ProductCardSizes.small,
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
